import React from 'react'
import Components from '../components/components.js'
import Navi from '../components/navi.js'
import Layout from '../components/layout/layout.js'
import SEO from '../components/layout/seo.js'

class StoryblokEntry extends React.Component {
  static getDerivedStateFromProps(props, state) {
    // if (state.story.uuid === props.pageContext.story.uuid) {
    //   return null
    // }

    return StoryblokEntry.prepareStory(props)
  }

  static prepareStory(props) {
    const story = Object.assign({}, props.pageContext.story)
    // const globalNavi = Object.assign({}, props.pageContext.globalNavi)
    story.content = JSON.parse(story.content)
    // globalNavi.content = JSON.parse(globalNavi.content)
    
    return { story }
  }

  constructor(props) {
    super(props)

    this.state = StoryblokEntry.prepareStory(props)
    this.state.locales = props.pageContext.locales
    this.state.navbar = props.pageContext.navbar
    this.state.footer = props.pageContext.footer
    this.state.cookie = props.pageContext.cookie
  }

  

  render() {
    let content = this.state.story.content

    // let globalNavi = this.state.globalNavi.content
    return (
      <Layout lang={this.state.story.lang} locales={this.state.locales} navbar={this.state.navbar} footer={this.state.footer} cookie={this.state.cookie}>
        <SEO lang={this.state.story.lang} meta={content.SEO}  locales={this.state.locales}/>
        {/* <Navi blok={globalNavi}></Navi> */}
        {React.createElement(Components(content.component), {key: content._uid, blok: content})}
      </Layout>
      
    )
  }
}

export default StoryblokEntry
