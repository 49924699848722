/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ lang, meta, locales }) => {

  const cookieAccepted = (name) => {
    var re = new RegExp(`${name}=([^;]+)`)
    var value = re.exec(typeof document !== 'undefined' && document.cookie)
    return value != null ? unescape(value[1]) : null
  }

  const [statisticCookies, setStatistCookies] = useState(false)
  const [marketingCookies, setMarketingCookies] = useState(false)


  if(cookieAccepted('rcl_statistics_consent') && !statisticCookies) {
    setStatistCookies(true)
  }
  if(cookieAccepted('rcl_marketing_consent')  && !marketingCookies) {
    setMarketingCookies(true)
  }

  const { site, cookie } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        cookie: storyblokEntry(field_component: {eq: "cookie_consent"}) {
          content
        }
      }
    `
  )
  const cookieContent = JSON.parse(cookie && cookie.content)
  const statisticsScripts = cookieContent && cookieContent.statistic_scripts
  const marketingScripts = cookieContent && cookieContent.marketing_scripts

  const url = typeof window !== 'undefined' ? window.location.origin : '';
  const metaDescription = "Skoletilbud"
  const metaTitle = "Vikasku"

  return (
    <Helmet
      htmlAttributes={{
        lang: lang === 'default' ? 'da' : lang,
      }}
      title={site.siteMetadata.title}
      titleTemplate={`%s | ${meta.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:image`,
          content: meta.og_image,
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:image`,
          content: meta.twitter_image || meta.og_image,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]}
    > 
    {locales && locales.length > 0 && locales.map((locale, i) => 
        <link rel="alternate" href={`${url}${locale.path !== '__home__' ? `${locale.lang === 'default' ? '' : '/' + locale.lang}/${locale.path || ''}` : `/${locale.lang.replace('default', '')}`}`} hrefLang={locale.lang.replace('default', 'da')} key={i}/>
    )}
    
    {statisticCookies ?
      statisticsScripts && statisticsScripts.map((st, i) => (
        <script key={i}>
          {st.script.replace('<script>', '').replace('</script>', '')}
        </script>
      ))
    : null}

    {marketingCookies ?
      marketingScripts && marketingScripts.map((mt, i) => (
        <script key={i}>
          {mt.script.replace('<script>', '').replace('</script>', '')}
        </script>
      ))
    : null}
    
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `da`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.object,
  locales: PropTypes.arrayOf(PropTypes.object),
}

export default SEO
